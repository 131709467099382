export const menuData=[
    {
        title: "Home",
        link: "/"
    },
    {
        title: "Directory",
        link: "/directory/"
    },
    {
        title: "Data Map",
        link:'/datamap/'
    },
    {
        title: "News",
        link: "/news/"
    },
    {
        title:"Grants",
        link: '/grants/'
    },
    {
        title: "For Undergrads",
        link: '/undergrads/'
    }
]


